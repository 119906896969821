<template>
    <div v-if="paragraphs" class="w_paragraphs-wrapper">
        <p v-for="(d, i) in paragraphs" :key="i" v-html="d"></p>
    </div>
</template>
<script>
export default {
    name: 'WorkParagraph',
    props: {
        content: null
    },
    computed: {
        paragraphs() {
            let paragraphs = this.content
			paragraphs  = typeof(paragraphs) == "string" ? [paragraphs] : paragraphs
			return paragraphs
        }
    }

}
</script>