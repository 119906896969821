
<template>
    <div class="wi_main fl-co-l" v-if="work">
        <div class="wi_back-wrapper upper fl-c-l"
            @click="updatePopupActive(false)"
            @mouseenter="updateCursor(true)"
            @mouseleave="updateCursor(false)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.8 23.8" v-bind:qsvg="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M3.5 11.9l6.8 6.7v-5.7h10v-2h-10V5.1z"/></svg>
            Back
        </div>
        <div class="wi_head fl-c-l fl-w">
            <h3 class="wi_title upper">{{ work.name }}</h3>
            <span class="wi_brief upper">{{ work.brief }}</span>
            <span class="wi_date upper">{{ work.date }}</span>
        </div>
        <div class="wi_content fl-co-l">
            <div id="jsContainer" v-if="type == 'p5'">
                <div id="p5_loading" class="wh ab fl-c">
                    <svg viewBox="0 0 100 100" preserveAspectRatio="xMidYMid" v-bind:qsvg="''" v-bind:class="'icon loading_icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><circle cx="50" cy="50" r="40" fill="none"><animate attributeName="stroke-dashoffset" dur="1.7s" repeatCount="indefinite" from="0" to="502"/><animate attributeName="stroke-dasharray" dur="2s" repeatCount="indefinite" values="150.6 100.4;1 250;150.6 100.4"/></circle></svg>
                </div>
            </div>
            <div class="vue_comp" v-if="type == 'vue'">
                <component :is="loadVueComp" />
            </div>
            <div class="wi_sections">
                <div v-for="(section, i) in sections" :key="i" class="wi_section">
                    <component
                    v-for="(part, ii) in section"
                    :key="ii + '_' + i"
                    :is="getComponentName(part.type)"
                    v-bind:content="part.content">
                    </component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import p5 from 'p5'
import { mapGetters, mapActions } from 'vuex'




import WorkParagraph from '@/components/work/elements/WorkParagraph.vue'
import WorkHeading from '@/components/work/elements/WorkHeading.vue'

export default {
    name: "WebInteraction",
    components: {
        WorkParagraph,
        WorkHeading
    },
    props: {
        payload: null
    },
    data: () => ({
        work          : null,
        p5Instance    : null,
        isClosed      : false
    }),
    computed: {
        ...mapGetters("GeneralStore", ['getPopupActive', 'getPopupMoving']),
        sections() {
			if(!this.work) return []
			return this.work.sections ? this.work.sections : []
        },
        type() { return this.payload.type },
        id() { return this.payload.id },
        loadVueComp() {
            if(this.type !== 'vue' || !this.id) return
            return () => import(`@/components/work/${this.id}/${this.id}.vue`)
        }
    },
    mounted() {
        this.createJs()
    },
    methods: {
        ...mapActions("GeneralStore", ['updatePopupActive','updateCursor']),
        async createJs() {
            this.work = await this.axios(`/pages/popup/${this.id}.json`)
        },
        async makeP5Instance() {
            let pp = await this.axios(`/assets/p5/${this.id}/index.js`)
            let func = p5 => { eval(pp) }  // eslint-disable-line no-unused-vars
            this.p5Instance = new p5(func)
        }
    },
    watch: {
        getPopupMoving(val) {
            if(!val && !this.getPopupActive) {
                if(this.p5Instance && this.type == 'p5'){
                    this.p5Instance.remove()
                }
            }
            if(!val && this.getPopupActive) {
                if(!this.p5Instance && this.type == 'p5'){
                    this.makeP5Instance()
                }
            }
        },
    },
}
</script>
