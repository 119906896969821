<template>
    <h3 v-if="content" class="w_meta upper" v-html="content"></h3>
</template>
<script>
export default {
    name: 'WorkHeading',
    props: {
        content: null
    }
}
</script>